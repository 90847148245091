import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'

import { Layout } from '../layouts/Layout'
import {
  Collapsible,
  SEO,
  generalFaqData,
  mobileFaqData,
  nbnFaqData,
} from '../components/shared'

const Faq = () => {
  useEffect(() => {
    window.location.hash &&
      window.setTimeout(() => navigate(window.location.hash), 10)
  }, [])

  return (
    <Layout>
      <SEO title="Pronto Broadband FAQ" />

      <main className="single">
        <div className="faq" id="faq">
          <div className="container">
            <h1 className="page-title">FAQs</h1>
            <h2 id="general">General</h2>

            {generalFaqData.map(faq => (
              <Collapsible key={faq.question} title={faq.question}>
                {faq.answer}
              </Collapsible>
            ))}

            <h2 id="nbn">nbn™</h2>

            {nbnFaqData.map(faq => (
              <Collapsible key={faq.question} title={faq.question}>
                {faq.answer}
              </Collapsible>
            ))}
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Faq
